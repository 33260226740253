import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import { Flex, Box } from '@chakra-ui/react';
import Wrapper from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Heading from '@interness/web-core/src/components/text/Heading/Heading';
import CallToAction from '@interness/theme-twentytwenty/src/components/CallToAction/CallToAction';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Wrapper style={{
      textAlign: 'justify'
    }} width={960} mdxType="Wrapper">
      <hr></hr>
  <Heading mdxType="Heading">Service</Heading>
  <Flex flexWrap={'wrap'} justifyContent='space-between' mdxType="Flex">
    <Box width={['100%', '100%', '50%', '50%']} p={'6'} mdxType="Box">
      <h3>Armbanduhren</h3>
      <ul>
        <li>mechanische Uhren / Handaufzug</li>
        <li>Quarzarmbanduhren</li>
        <li>Automatikuhren</li>
        <li>Batteriewechsel</li>
        <li>Komplette Revisionen</li>
        <li>Glasersatz</li>
        <li>Leder-, Metall-, Kuststoffuhrarmbänder</li>
        <li>Bandanpassung</li>
        <li>Wasserdichtigkeitstest bis 10 Bar</li>
      </ul>
    </Box>
    <Box width={['100%', '100%', '50%', '50%']} p={'6'} mdxType="Box">
      <h3>Goldschmiede Service</h3>
      <ul>
        <li>Goldschmiedereparaturen aller Art</li>
        <li>Kette und Ösen zulöten, Verschlüsse ersetzen</li>
        <li>Ringgrößen ändern</li>
        <li>Perlenketten neu fassen oder knoten</li>
        <li>Trauringumarbeitung</li>
        <li>Neuanfertigungen</li>
        <li>Schmuck reinigen und aufarbeiten</li>
        <li>Gravuren in Ringe, Anhänger, Uhren, usw.</li>
        <li>Schützenorden</li>
      </ul>
    </Box>
    <Box width={['100%', '100%', '50%', '50%']} p={'6'} mdxType="Box">
      <h3>Altgold</h3>
      <ul>
        <li>Wir kaufen Altgold aus vielen unterschiedlichen Bereichen an</li>
        <li>Zahngold, Goldnuggets, Altgold aus Schmuckstücken</li>
        <li>Wir prüfen fachkundig und unkompliziert und vergüten nach aktuellen Edelmetallkursen</li>
        <li>Altgoldankauf ist Vertrauenssache</li>
        <li>Verkauf von Edelmetallen in Form von Barren und Münzen.</li>
      </ul>
    </Box>
    <Box width={['100%', '100%', '50%', '50%']} p={'6'} mdxType="Box">
      <h3>Großuhren</h3>
      <ul>
        <li>Heim- und Tischuhren, Jahresuhren</li>
        <li>Standuhren</li>
        <li>Regulatoren</li>
      </ul>
    </Box>
  </Flex>
      <hr></hr>
    </Wrapper>
    <CallToAction mdxType="CallToAction" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      